@font-face {
    font-family: 'Spartan-Black';
    src: url('../Spartan-Black.ttf');
}

@font-face {
    font-family: 'time_et';
    src: url('../time.et.woff');
}

#redirectButton {
    width: 280px;
    height: 50px;
    border-radius: 10px;
    border: none;
    margin-top: 20px;
    background-color: #0e4871;
    box-shadow: -1px 0px 17px 16px #CFA836;
    display: flex;
    align-items: center;
    justify-content: center;

}

.buttonText {
    color: #edc653 !important;
    text-decoration: none;
    font-size: 24px;
    font-family: 'time_et';
}

.soccerJersey {
    width: 25px;
    height: 25px;
    margin-right: 4px;
}

.shareText {
    text-transform: uppercase;
    font-family: sans-serif;
    font-size: 31px;
}