@font-face {
    font-family: 'time_et';
    src: url('../time.et.woff');
}

@font-face {
    font-family: 'Spartan_Black';
    src: url('../Spartan-Black.ttf');
}

@media (min-width: 1024px) and (max-width: 2000px) {
    .fullContainer {
        height: 100vh;
        width: 100%;
    }

    .changeJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .changeJerseyImgContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ballAnim {
        width: 300px;
        height: 300px;
        margin-left: -50px;

    }

    #theSource {
        display: none;
    }

    h1 {
        position: absolute;
        text-align: center;
        color: white;
        margin-top: 90px;
        font-family: 'Spartan_Black';
        font-size: 25px;
        text-transform: uppercase;
    }

    .h12 {
        position: absolute;
        text-align: center;
        margin-top: 150px;
        font-size: 80px;
        color: white;
        font-family: 'Spartan_Black';
    }

    .changeJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .changeJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 450px;
        height: 50px;
        border-radius: 0 0 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-bottom: 20px;
        font-family: 'time_et';
    }

    .inputsAndButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    input {
        width: 400px;
        height: 50px;
        text-align: center;
        border-radius: 10px;
        border: none;
        margin-bottom: 20px;
        font-family: 'time_et';
        text-transform: uppercase;
    }

    input:focus {
        outline: none;
    }


    .changeJerseyLabels {
        font-size: 22px;
        color: white;
        margin-bottom: 5px;
        font-family: 'time_et';
    }

    .changeJerseyWrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .changeJerseyButton {
        width: 250px;
        height: 40px;
        text-align: center;
        border: none;
        font-size: 22px;
        border-radius: 50px;
        margin: 0 300px;
        background-color: #edc653;
        color: #0e4871;
        font-family: 'time_et';
    }

    .changeJerseyButtonLink {
        text-decoration: none !important;
        color: #0e4871;
        font-weight: bold;
    }

    .logoIcon {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .fullContainer {
        height: 100vh;
        width: 100%;
    }

    .changeJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .changeJerseyImgContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ballAnim {
        width: 300px;
        height: 300px;
        margin-left: -50px;

    }

    #theSource {
        display: none;
    }

    h1 {
        position: absolute;
        text-align: center;
        color: white;
        margin-top: 90px;
        font-family: 'Spartan_Black';
        font-size: 25px;
        text-transform: uppercase;
    }

    .h12 {
        position: absolute;
        text-align: center;
        margin-top: 150px;
        font-size: 80px;
        color: white;
        font-family: 'Spartan_Black';
    }

    .changeJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .changeJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 450px;
        height: 50px;
        border-radius: 0 0 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-bottom: 20px;
        font-family: 'time_et';
    }

    .inputsAndButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    input {
        width: 400px;
        height: 50px;
        text-align: center;
        border-radius: 10px;
        border: none;
        margin-bottom: 20px;
        font-family: 'time_et';
        text-transform: uppercase;
    }

    input:focus {
        outline: none;
    }


    .changeJerseyLabels {
        font-size: 22px;
        color: white;
        margin-bottom: 5px;
        font-family: 'time_et';
    }

    .changeJerseyWrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .changeJerseyButton {
        width: 250px;
        height: 40px;
        text-align: center;
        border: none;
        font-size: 22px;
        border-radius: 50px;
        margin: 0 300px;
        background-color: #edc653;
        color: #0e4871;
        font-family: 'time_et';
    }

    .changeJerseyButtonLink {
        text-decoration: none !important;
        color: #0e4871;
        font-weight: bold;
    }

    .logoIcon {
        width: 100px;
        height: 100px;
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .fullContainer {
        height: 100vh;
        width: 100%;
    }

    .changeJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .changeJerseyImgContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ballAnim {
        width: 300px;
        height: 300px;
        margin-left: -50px;

    }

    #theSource {
        display: none;
    }

    h1 {
        position: absolute;
        text-align: center;
        color: white;
        margin-top: 90px;
        font-family: 'Spartan_Black';
        font-size: 25px;
        text-transform: uppercase;
    }

    .h12 {
        position: absolute;
        text-align: center;
        margin-top: 150px;
        font-size: 80px;
        color: white;
        font-family: 'Spartan_Black';
    }

    .changeJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .changeJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 450px;
        height: 50px;
        border-radius: 0 0 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-bottom: 20px;
        font-family: 'time_et';
    }

    .inputsAndButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    input {
        width: 400px;
        height: 50px;
        text-align: center;
        border-radius: 10px;
        border: none;
        margin-bottom: 20px;
        font-family: 'time_et';
        text-transform: uppercase;
    }

    input:focus {
        outline: none;
    }


    .changeJerseyLabels {
        font-size: 22px;
        color: white;
        margin-bottom: 5px;
        font-family: 'time_et';
    }

    .changeJerseyWrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .changeJerseyButton {
        width: 250px;
        height: 40px;
        text-align: center;
        border: none;
        font-size: 22px;
        border-radius: 50px;
        margin: 0 300px;
        background-color: #edc653;
        color: #0e4871;
        font-family: 'time_et';
    }

    .changeJerseyButtonLink {
        text-decoration: none !important;
        color: #0e4871;
        font-weight: bold;
    }

    .logoIcon {
        width: 100px;
        height: 100px;
        display: none !important;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .fullContainer {
        height: 100vh !important;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .changeJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* padding: 50px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 50%;
    }

    .changeJerseyHeadText {
        display: none;
    }

    .ballAnim {
        width: 300px;
        height: 300px;
        margin-left: -50px;

    }

    .changeJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .changeJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 100%;
        height: 45px;
        border-radius: 0 0 20px 20px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'time_et';
    }

    .changeJerseyImgContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        position: absolute;
        text-align: center;
        color: white;
        margin-top: 110px;
        font-size: 20px;
        font-family: 'Spartan_Black';
        text-transform: uppercase;
    }

    .h12 {
        position: absolute;
        text-align: center;
        margin-top: 155px;
        font-size: 70px;
        color: white;
        font-weight: bold;
    }

    .inputsAndButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-family: 'time_et';
    }

    .changeJerseyLabels {
        color: white;
        font-size: 20px;
        margin-bottom: 5px;
        font-family: 'time_et';
    }

    input {
        width: 300px;
        height: 40px;
        border: none;
        border-radius: 10px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    ::placeholder {
        font-family: 'time_et';
        letter-spacing: 1px;
    }

    input:focus {
        outline: none;
    }

    .changeJerseyButton {
        width: 200px;
        height: 40px;
        border: none;
        font-size: 18px;
        border-radius: 50px;
        background-color: #edc653;
        color: #0e4871;
        margin: 10px 0 30px 0;
        font-family: 'time_et';
    }

    .changeJerseyButtonLink {
        text-decoration: none !important;
        color: #0e4871;
    }

    .logoIcon {
        display: none;
    }
}

@media (min-width: 120px) and (max-width: 480px) {
    .fullContainer {
        height: 100vh !important;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .changeJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        /* padding: 50px; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 50%;
    }

    .ballAnim {
        width: 300px;
        height: 300px;
        margin-left: -50px;

    }

    .changeJerseyHeadText {
        display: none;
    }

    .changeJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
    }

    .changeJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 100%;
        height: 45px;
        border-radius: 0 0 20px 20px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'time_et';
    }

    .changeJerseyImgContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        position: absolute;
        text-align: center;
        color: white;
        margin-top: 110px;
        font-size: 20px;
        font-family: 'Spartan_Black';
        text-transform: uppercase;
    }

    .h12 {
        position: absolute;
        text-align: center;
        margin-top: 155px;
        font-size: 70px;
        color: white;
        font-weight: bold;
    }

    .inputsAndButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        font-family: 'time_et';
    }

    .changeJerseyLabels {
        color: white;
        font-size: 20px;
        margin-bottom: 5px;
        font-family: 'time_et';
    }

    input {
        width: 300px;
        height: 40px;
        border: none;
        border-radius: 10px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
    }

    ::placeholder {
        font-family: 'time_et';
        letter-spacing: 1px;
    }

    input:focus {
        outline: none;
    }

    .changeJerseyButton {
        width: 200px;
        height: 40px;
        border: none;
        font-size: 18px;
        border-radius: 50px;
        background-color: #edc653;
        color: #0e4871;
        margin: 10px 0 30px 0;
        font-family: 'time_et';
    }

    .changeJerseyButtonLink {
        text-decoration: none !important;
        color: #0e4871;
    }

    .logoIcon {
        display: none;
    }
}