.container {
    width: 100%;
    position: relative;
    height: 100vh;
    width: 100%;
}

.cameraComponents {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

}

.cameraComponentsWrapper {
    position: relative;
    width: 640px;
    height: 480px;
}

#click-photo {
    position: absolute !important;
    /* bottom: 0; */
    left: 40%;
    padding: 15px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 50px;
}

#canvas {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 999;
}

#outputImage {
    display: none;
}

#carouselLoader {
    display: none;
}

.wrapper1 {
    background-image: url("../Images/InGame-Background.jpg");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    position: relative;
    height: 60%;
}

.changedHeadText {
    width: 300px;
    position: absolute;
    top: 50px;
    left: 50px;
}

.sliderContainer {
    width: 300px;
    margin-top: 100px;
}

.slick-prev::before {
    font-family: 'slick';
    font-size: 60px !important;
    line-height: 1;
    opacity: .75;
    color: #edc653 !important;
    -webkit-font-smoothing: antialiased;

}

.slick-next:before {
    font-family: 'slick';
    font-size: 60px !important;
    line-height: 1;
    opacity: .75;
    color: #edc653 !important;
    -webkit-font-smoothing: antialiased;

}

.carouselWrapper {
    position: relative;
}

.loading {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 13px;
    left: 50px;
}



.headText {
    width: 300px;
    height: 100px;
}

.slider {
    position: absolute;
    right: 0;
    bottom: 0;
}

img {
    width: 300px;
    height: 400px;
}

.logoIcons {
    width: 100px;
    height: 100px;
}

.wrapper2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../Images/GamePrompt-Background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    height: 40%;
    width: 100%;
}

.bottomWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin: 0 400px;
}

.changeFaceButton {
    width: 350px;
    height: 50px;
    border-radius: 50px;
    border: none;
    background-color: #edc653;
    color: #0e4871;
    font-size: 26px;
    font-weight: bold;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    font-family: "time_et";
}

.insAndFac {
    font-size: 22px;
    font-family: sans-serif;
    margin: 0 3px 0 3px;
}

.btnIcon {
    position: absolute;
    left: 2.5px;
    padding: 10px 22px;
    border-radius: 25px 3px 3px 25px;
    /* background-color: #c19d2f; */
}

.cameraAndGallaryIcons {
    width: 25px;
    height: 25px;
    display: flex;
    margin-top: 0 !important;
}


.changeFaceButtonLink {
    text-decoration: none !important;
    color: #0e4871;
}

.logoIcons {
    display: none;
}

#avatar {
    display: none;
}

.buttonIcons {
    width: 30px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;

}




@media(min-width:1024px) and (min-width: 2000px) {
    .container {
        width: 100%;
        position: relative;
        height: 100vh;
        width: 100%;
    }

    .cameraComponents {
        position: absolute;
        top: 2%;
        left: 31%;
        z-index: 999;
        width: 640px;
        height: 480px;
    }

    .cameraComponentsWrapper {
        position: relative;
        width: 640px;
        height: 480px;
    }

    #click-photo {
        position: absolute !important;
        /* bottom: 0; */
        left: 40%;
        padding: 15px;
        width: 70px;
        height: 70px;
        border: none;
        border-radius: 50px;
    }

    #canvas {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 999;
    }

    #outputImage {
        display: none;
    }

    #carouselLoader {
        display: none;
    }

    .wrapper1 {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 60%;
    }

    .changedHeadText {
        width: 300px;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .sliderContainer {
        width: 300px;
        margin-top: 100px;
    }

    .slick-prev::before {
        font-family: 'slick';
        font-size: 60px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-left: -339px;

    }

    .slick-next:before {
        font-family: 'slick';
        font-size: 60px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-right: -300px;
    }

    .carouselWrapper {
        position: relative;
    }

    .loading {
        width: 90px;
        height: 90px;
        position: absolute;
        top: 13px;
        left: 50px;
    }



    .headText {
        width: 300px;
        height: 100px;
    }

    .slider {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    img {
        width: 300px;
        height: 400px;
    }

    .logoIcons {
        width: 100px;
        height: 100px;
    }

    .wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../Images/GamePrompt-Background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 40%;
        width: 100%;
    }

    .bottomWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin: 0 400px;
    }

    .changeFaceButton {
        width: 350px;
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: #edc653;
        color: #0e4871;
        font-size: 26px;
        font-weight: bold;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-decoration: none;
    }

    .btnIcon {
        position: absolute;
        left: 2.5px;
        padding: 10px 22px;
        border-radius: 25px 3px 3px 25px;
        /* background-color: #c19d2f; */
    }

    .cameraAndGallaryIcons {
        width: 25px;
        height: 25px;
        display: flex;
        margin-top: 0 !important;
    }

    .changeFaceButtonLink {
        text-decoration: none !important;
        color: #0e4871;
    }

    .logoIcons {
        display: inline;
    }

    #avatar {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .container {
        width: 100%;
        position: relative;
        height: 100vh;
        width: 100%;
    }

    #outputImage {
        /* position: absolute;
    top: 0;
    left: 50; */
        display: none;
    }

    #carouselLoader {
        display: none;
    }

    .wrapper1 {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 60%;
    }

    .changedHeadText {
        width: 300px;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .sliderContainer {
        width: 300px;
        margin-top: 100px;
    }

    .slick-prev::before {
        font-family: 'slick';
        font-size: 60px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-left: -339px;

    }

    .slick-next:before {
        font-family: 'slick';
        font-size: 60px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-right: -300px;
    }

    .carouselWrapper {
        position: relative;
    }

    .loading {
        width: 90px;
        height: 90px;
        position: absolute;
        top: 13px;
        left: 50px;
    }

    .headText {
        width: 300px;
        height: 100px;
    }

    .slider {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    img {
        width: 300px;
        height: 400px;
        margin-top: -3px;
    }

    .logoIcons {
        width: 100px;
        height: 100px;
    }

    .wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../Images/GamePrompt-Background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: 40%;
        width: 100%;
    }

    .bottomWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin: 0 400px;
    }

    .changeFaceButton {
        width: 350px;
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: #edc653;
        color: #0e4871;
        font-size: 26px;
        font-weight: bold;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .changeFaceButtonLink {
        text-decoration: none !important;
        color: #0e4871;
    }

    #avatar {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .container {
        width: 100%;
        position: relative;
        height: 100vh;
        width: 100%;
    }

    .wrapper1 {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 60%;
    }

    .changedHeadText {
        display: none;
    }

    .sliderContainer {
        width: 280px;
        margin-top: 100px;
    }

    .slick-prev::before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-left: -20px;

    }

    .slick-next {
        right: -20px !important;
    }

    .slick-next:before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
    }

    .headText {
        width: 300px;
        height: 100px;
    }

    img {
        width: 280px;
        height: 380px;
    }

    .logoIcons {
        display: none;
    }

    .wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../Images/GamePrompt-Background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 40%;
    }

    .bottomWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .carouselWrapper {
        position: relative;
    }

    .loading {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 25px;
        left: 60px;
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin: 0 400px;
    }

    #outputImage {
        display: none;
    }

    .changeFaceButton {
        width: 350px;
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: #edc653;
        color: #22577E;
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        text-decoration: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .changeFaceButtonLink {
        text-decoration: none !important;
        color: #22577E;
    }

    #avatar {
        display: none;
    }

    #carouselLoader {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .container {
        width: 100%;
        position: relative;
        height: 100vh;
        width: 100%;
    }

    .wrapper1 {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 60%;
    }

    .changedHeadText {
        display: none;
    }

    .sliderContainer {
        width: 280px;
        margin-top: 100px;
    }

    .slick-prev::before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-left: -20px;

    }

    .slick-next {
        right: -20px !important;
    }

    .slick-next:before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
    }

    .headText {
        width: 300px;
        height: 100px;
    }

    img {
        width: 280px;
        height: 380px;
    }

    .logoIcons {
        display: none;
    }

    .wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../Images/GamePrompt-Background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 40%;
    }

    .bottomWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .carouselWrapper {
        position: relative;
    }

    .loading {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 25px;
        left: 60px;
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin: 0 400px;
    }

    #outputImage {
        display: none;
    }

    .changeFaceButton {
        width: 350px;
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: #edc653;
        color: #22577E;
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        text-decoration: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .changeFaceButtonLink {
        text-decoration: none !important;
        color: #22577E;
    }

    #avatar {
        display: none;
    }

    #carouselLoader {
        display: none;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .container {
        width: 100%;
        position: relative;
        height: 100vh;
        width: 100%;
    }

    .cameraComponents {
        position: absolute;
        top: 0;
        width: 100%;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cameraComponentsWrapper {
        position: relative;
        width: 240px;
        height: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
    }

    #click-photo {
        position: absolute !important;
        /* bottom: 0; */
        left: 40%;
        padding: 15px;
        width: 70px;
        height: 70px;
        border: none;
        border-radius: 50px;
    }

    #video {
        width: 240px;
        height: 320px;
    }

    #canvas {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 999;
        width: 240px;
        height: 320px;
    }

    .wrapper1 {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 60%;
    }

    .changedHeadText {
        display: none;
    }

    .sliderContainer {
        width: 280px;
        margin-top: 100px;
    }

    .slick-prev::before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
        margin-left: -20px;

    }

    .slick-next {
        right: -20px !important;
    }

    .slick-next:before {
        font-family: 'slick';
        font-size: 45px !important;
        line-height: 1;
        opacity: .75;
        color: #edc653 !important;
        -webkit-font-smoothing: antialiased;
    }

    .headText {
        width: 300px;
        height: 100px;
    }

    img {
        width: 280px;
        height: 380px;
    }

    .logoIcons {
        display: none;
    }

    .wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../Images/GamePrompt-Background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 40%;
    }

    .bottomWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .carouselWrapper {
        position: relative;
    }

    .loading {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 25px;
        left: 60px;
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin: 0 400px;
    }

    #outputImage {
        display: none;
    }

    .changeFaceButton {
        width: 350px;
        height: 50px;
        border-radius: 50px;
        border: none;
        background-color: #edc653;
        color: #22577E;
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        text-decoration: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btnIcon {
        position: absolute;
        left: 15px;
        padding: 10px 22px;
        border-radius: 25px 3px 3px 25px;
        /* background-color: #c19d2f; */
    }

    .cameraAndGallaryIcons {
        width: 25px;
        height: 25px;
        display: flex;
        margin-top: 0 !important;
    }

    .changeFaceButtonLink {
        text-decoration: none !important;
        color: #22577E;
    }

    #avatar {
        display: none;
    }

    #carouselLoader {
        display: none;
    }

    .facebookAndInsta {
        width: 250px !important;
        height: 300px !important;
        margin-top: 30px;

    }
}