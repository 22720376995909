@media (min-width: 1281px) {
    .changedFaceContainer {
        height: 100vh;
        width: 100%;

    }

    .changedFace {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 70%;
    }

    .confetti {
        width: 100vw !important;
        height: 100vh !important;
        z-index: 999 !important;
    }

    .changedFaceWrapper1 {
        position: relative;
        background-color: red;
    }

    .changedFaceHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
    }


    .changedFaceImgContainer {
        margin-bottom: -95px;
        width: 375.71px;
        height: 600.85px;
    }


    .cropedFaceImg {
        width: 90px;
        height: 113px;
        position: absolute;
        z-index: 1;
        margin-top: 0 !important;
        top: 54px;
        left: 152.5px;
    }

    .cropedFaceImg1 {
        width: 375.71px;
        height: 600.85px;
        position: absolute;
        z-index: 3;
        margin-top: -12px !important;
    }

    .changedFace2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 30%;
    }

    .changedFaceHeadText2 {
        background-color: #edc653;
        color: #22577E;
        width: 375px;
        height: 200px;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
        margin: 0 300px 50px 300px;
        position: relative;
        z-index: 3;
    }

    .downloadText {
        margin-top: -100px !important;
    }

    .iconsContainer {
        position: absolute;
        height: 100px;

    }

    .downloadIcons1 {
        width: 80px;
        height: 80px;
    }

    .changedFaceLogoIcons {
        width: 120px;
        height: 120px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

@media (min-width: 481px) and (max-width: 767px) {}

@media (min-width: 320px) and (max-width: 480px) {
    .changedFaceContainer {
        height: 100vh !important;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .changedFace {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 40%;
    }

    .confetti {

        width: 100vw !important;
        height: 100vh !important;
    }

    .changedFaceHeadText {
        display: none;
    }

    .changedFaceWrapper1 {
        position: relative;
        width: 250px;
        height: 400px;
        background-color: red;
    }



    .cropedFaceImg {
        position: absolute;
        width: 57px;
        height: 75px;
        top: 43px;
        left: 104px;
    }

    .cropedFaceImg1 {
        position: absolute;
        width: 250px;
        height: 400px;
    }


    .changedFace2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .changedFaceHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 100%;
        height: 200px;
        border-radius: 0 0 30px 30px;
        font-size: 25px;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 250px;
        z-index: 4;
    }

    .downloadIcons1 {
        width: 90px;
        height: 90px;
    }

    .changedFaceLogoIcons {
        display: none;
    }
}