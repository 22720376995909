@font-face {
    font-family: 'time_et';
    src: url('../time.et.woff');
}

@media (min-width: 1024px) and (max-width: 2000px) {
    .changedFullContainer {
        height: 100vh;
        width: 100vw;
    }

    .changedJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 70%;
        width: 100%;
    }

    .changedJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
        z-index: 999;
    }

    .changedJerseyImgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 430px;
        height: 500px;
    }

    .jerseyName {
        position: absolute;
        top: 5px;
        font-size: 22px;
    }

    .jerseyNumber {
        position: absolute;
        font-size: 65px;
        top: 65px;

    }

    .changedJerseyImg {
        width: 430px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .changedJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        text-align: center;
        height: 30%;
        width: 100%;
    }

    .changedJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 430px;
        height: 200px;
        border-radius: 0 0 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
    }

    span {
        font-size: 34px;
        font-family: 'time_et';
    }

    .downloadIcons {
        width: 100px;
        height: 100px;
        margin-top: 10px;
    }

}

@media (min-width: 765px) and (max-width: 1024px) {
    .changedFullContainer {
        height: 100vh;
        width: 100vw;
    }

    .changedJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 70%;
        width: 100%;
    }

    .changedJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
        z-index: 999;
    }

    .changedJerseyImgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 430px;
        height: 500px;
    }

    .jerseyName {
        position: absolute;
        top: 5px;
        font-size: 22px;
    }

    .jerseyNumber {
        position: absolute;
        font-size: 65px;
        top: 65px;

    }

    .changedJerseyImg {
        width: 430px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .changedJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        text-align: center;
        height: 30%;
        width: 100%;
    }

    .changedJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 430px;
        height: 200px;
        border-radius: 0 0 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
    }

    span {
        font-size: 34px;
        font-family: 'time_et';
    }

    .downloadIcons {
        width: 100px;
        height: 100px;
        margin-top: 10px;
    }

}

@media (min-width: 765px) and (max-width: 1024px) and (orientation: landscape) {
    .changedFullContainer {
        height: 100vh;
        width: 100vw;
    }

    .changedJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 70%;
        width: 100%;
    }

    .changedJerseyHeadText {
        width: 300px;
        height: 100px;
        align-self: left;
        position: absolute;
        top: 50px;
        left: 50px;
    }

    .changedJerseyImgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 430px;
        height: 500px;
    }

    .jerseyName {
        position: absolute;
        top: 5px;
        font-size: 22px;
    }

    .jerseyNumber {
        position: absolute;
        font-size: 65px;
        top: 65px;

    }

    .changedJerseyImg {
        width: 430px;
        height: 500px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .changedJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        text-align: center;
        height: 30%;
        width: 100%;
    }

    .changedJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 430px;
        height: 200px;
        border-radius: 0 0 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: bold;
    }

    span {
        font-size: 34px;
        font-family: 'time_et';
    }

    .downloadIcons {
        width: 100px;
        height: 100px;
        margin-top: 10px;
    }

}

@media (min-width: 481px) and (max-width: 767px) {
    .changedFullContainer {
        height: 100vh !important;
        width: 100vw !important;
        margin: 0;
        padding: 0;
    }

    .changedJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 60%;
        width: 100%;
    }

    .changedJerseyHeadText {
        display: none;
    }

    .changedJerseyImgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 370px !important;
        height: 400px !important;
    }

    .jerseyName {
        position: absolute;
        top: -25px !important;
        font-size: 18px;
    }

    .jerseyNumber {
        position: absolute;
        font-size: 60px;
        top: 15px !important;

    }

    .changedJerseyImg {
        width: 370px;
        height: 400px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .changedJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        text-align: center;
        height: 40%;
        width: 100%;
    }

    .changedJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 368px;
        height: 250px;
        border-radius: 0 0 30px 30px;
        font-size: 25px;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 200px;
    }

    span {
        font-size: 35px;
        font-family: 'time_et';
    }

    .downloadIcons {
        width: 90px;
        height: 90px;
        margin-top: 20px;
    }
}

@media (min-width: 149px) and (max-width: 480px) {
    .changedFullContainer {
        height: 100vh !important;
        width: 100vw !important;
        margin: 0;
        padding: 0;
    }

    .changedJersey {
        background-image: url("../Images/InGame-Background.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        position: relative;
        height: 60%;
        width: 100%;
    }

    .changedJerseyHeadText {
        display: none;
    }



    .changedJerseyImgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 370px;
        height: 400px;
    }

    .changedJerseyImg {
        width: 100%;
        height: 400px;
        position: absolute;
        top: 0;
        left: 0;

    }

    .jerseyName {
        position: absolute;
        top: -25px !important;
        font-size: 18px;
    }

    .jerseyNumber {
        position: absolute;
        font-size: 60px;
        top: 15px !important;

    }

    .changedJersey2 {
        background-image: url("../Images/GamePrompt-Background.png");
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: top;
        text-align: center;
        height: 40%;
        width: 100%;
    }

    .changedJerseyHeadText2 {
        background-color: #edc653;
        color: #0e4871;
        width: 367px;
        height: 250px;
        border-radius: 0 0 30px 30px;
        font-size: 25px;
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 200px;
    }

    span {
        font-size: 35px;
        font-family: 'time_et';
    }

    .downloadIcons {
        width: 90px;
        height: 90px;
        margin-top: 20px;
    }
}