@font-face {
    font-family: 'time_et';
    src: url('../time.et.woff');
}



@media (min-width: 1024px) and (max-width: 2000px) {

    .home {
        width: 100vw;
        height: 100vh;
        background-image: url("../Images/Homepage-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .homeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .containerWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .container1 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 650px;
        position: relative;
        margin: 50px 0 0 50px;
    }

    .homeJersey {
        position: absolute;
        top: 150px;
        width: 350px;
        height: 450px;
    }

    .homeJerseyText {
        position: absolute;
        width: 400px;
        height: 100px;
        left: 0;
        top: 0;
    }

    .homeLogoContainer,
    .homeLogo {
        position: absolute;
        width: 250px;
        height: 120px;
        z-index: 999;
        top: 70px;
    }

    .homeButton1,
    .homeButton2 {
        width: 350px;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
        background-color: #edc653;
        color: #0e4871;
        padding-top: 10px;
        font-size: 26px;
        font-family: 'time_et';
        font-weight: bold;
        letter-spacing: 1px;
    }

    .changeJerseyLink {
        color: #0e4871;
        text-decoration: none !important;
    }

    .container2 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 650px;
        position: relative;
        margin: 50px 50px 0 0;
    }

    .homeFace {
        position: absolute;
        top: 150px;
        width: 360px;
        height: 450px;
    }

    .homeFaceText {
        position: absolute;
        width: 400px;
        height: 100px;
        top: 0;
        right: 0;
    }

    .homeButton2 {
        width: 350px;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
    }

    .bottomText {
        display: none;
    }

    .arrowDown {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .home {
        width: 100vw;
        height: 100vh;
        background-image: url("../Images/Homepage-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .homeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .containerWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .container1 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 550px;
        position: relative;
        margin: 50px 0 0 50px;
    }

    .homeJersey {
        position: absolute;
        top: 150px;
        width: 250px;
        height: 350px;
    }

    .homeJerseyText {
        position: absolute;
        width: 300px;
        height: 70px;
        left: 0;
        top: 0;
    }

    .homeLogoContainer,
    .homeLogo {
        position: absolute;
        width: 150px;
        height: 70px;
        z-index: 999;
        top: 70px;
    }

    .homeButton1,
    .homeButton2 {
        width: 300px !important;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
        background-color: #edc653;
        color: #0e4871;
        padding-top: 10px;
        font-size: 26px;
        font-family: 'time_et';
        font-weight: bold;
        letter-spacing: 1px;
    }

    .changeJerseyLink {
        color: #0e4871;
        text-decoration: none !important;
    }

    .container2 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 550px;
        position: relative;
        margin: 50px 50px 0 0;
    }

    .homeFace {
        position: absolute;
        top: 150px;
        width: 250px;
        height: 350px;
    }

    .homeFaceText {
        position: absolute;
        width: 300px;
        height: 70px;
        top: 0;
        right: 0;
    }

    .homeButton2 {
        width: 350px;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
    }

    .bottomText {
        display: none;
    }

    .arrowDown {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .home {
        width: 100vw;
        height: 100vh;
        background-image: url("../Images/Homepage-Background.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .homeWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .containerWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .container1 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 550px;
        position: relative;
        margin: 50px 0 0 50px;
    }

    .homeJersey {
        position: absolute;
        top: 150px;
        width: 250px;
        height: 350px;
    }

    .homeJerseyText {
        position: absolute;
        width: 300px;
        height: 70px;
        left: 0;
        top: 0;
    }

    .homeLogoContainer,
    .homeLogo {
        position: absolute;
        width: 150px;
        height: 70px;
        z-index: 999;
        top: 70px;
    }

    .homeButton1,
    .homeButton2 {
        width: 300px !important;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
        background-color: #edc653;
        color: #0e4871;
        padding-top: 10px;
        font-size: 26px;
        font-family: 'time_et';
        font-weight: bold;
        letter-spacing: 1px;
    }

    .changeJerseyLink {
        color: #0e4871;
        text-decoration: none !important;
    }

    .container2 {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 550px;
        position: relative;
        margin: 50px 50px 0 0;
    }

    .homeFace {
        position: absolute;
        top: 150px;
        width: 250px;
        height: 350px;
    }

    .homeFaceText {
        position: absolute;
        width: 300px;
        height: 70px;
        top: 0;
        right: 0;
    }

    .homeButton2 {
        width: 350px;
        height: 53px;
        display: flex;
        align-self: flex-end;
        justify-content: center;
        border-radius: 50px;
        border: none;
        z-index: 999;
    }

    .bottomText {
        display: none;
    }

    .arrowDown {
        display: none;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .home {
        width: 100%;
    }

    .homeLogoContainer {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-image: url("../Images/InGame-Background.jpg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .arrowDown {
        width: 20px;
        height: 80px;
        display: inline;
    }

    .homeWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center center;
        align-items: center center;
        text-align: center;
        margin: 0;
        padding: 0;

    }

    .containerWrapper {
        background-image: url("../Images/Homepage-Background.png");
        background-position: right;
        background-size: cover;
    }

    .container1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .homeJersey {
        width: 235px;
        height: 300px;
        margin-top: 40px;
    }

    .homeJerseyText {
        display: none;
    }

    .homeButton1,
    .homeButton2 {
        width: 210px;
        height: 40px;
        border: none;
        border-radius: 50px;
        background-color: #edc653;
        color: #0e4871;
        font-size: 18.5px;
        font-weight: bold;
        font-family: 'time_et';
    }

    .changeJerseyLink {
        color: #0e4871;
        text-decoration: none !important;
    }

    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 40px 0 40px 0;
    }

    .homeFace {
        width: 230px;
        height: 300px;
    }

    .homeFaceText {
        display: none;
    }

    .homeLogo {
        width: 350px;
        height: 180px;
        display: inline-block;
        vertical-align: middle;

    }

    .bottomTextContainer {
        background: url("../Images/GamePrompt-Background.png");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bottomText {
        width: 320px;
        height: 95px;
        align-self: left;
        margin: 70px 0 50px 0;
        display: inline;
    }

}

@media (min-width: 120px) and (max-width: 480px) {
    .home {
        width: 100%;
    }

    .homeLogoContainer {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-image: url("../Images/InGame-Background.jpg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .arrowDown {
        width: 20px;
        height: 80px;
        display: inline;
    }

    .homeWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center center;
        align-items: center center;
        text-align: center;
        margin: 0;
        padding: 0;

    }

    .containerWrapper {
        background-image: url("../Images/Homepage-Background.png");
        background-position: right;
        background-size: cover;
    }

    .container1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .homeJersey {
        width: 235px;
        height: 300px;
        margin-top: 40px;
    }

    .homeJerseyText {
        display: none;
    }

    .homeButton1,
    .homeButton2 {
        width: 210px;
        height: 40px;
        border: none;
        border-radius: 50px;
        background-color: #edc653;
        color: #0e4871;
        font-size: 18.5px;
        font-weight: bold;
        font-family: 'time_et';
    }

    .changeJerseyLink {
        color: #0e4871;
        text-decoration: none !important;
    }

    .container2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 40px 0 40px 0;
    }

    .homeFace {
        width: 230px;
        height: 300px;
    }

    .homeFaceText {
        display: none;
    }

    .homeLogo {
        width: 350px;
        height: 180px;
        display: inline-block;
        vertical-align: middle;

    }

    .bottomTextContainer {
        background: url("../Images/GamePrompt-Background.png");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .bottomText {
        width: 320px;
        height: 95px;
        align-self: left;
        margin: 70px 0 50px 0;
        display: inline;
    }


}